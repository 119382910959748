<template>
  <BaseChart title="Répartition des dépôts" :chart-options="depositsPieChartOptions" />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { PieChart } from "echarts/charts";
import { use } from "echarts/core";
import BaseChart from "./BaseChart.vue";
import {
  getPaymentMethodName,
  groupDepositsByMethod,
  groupPaymentsByMethod,
  getPaymentMethodColor,
} from "~/utils/paymentMethod";
import { PaymentMethod } from "~/types/MethodPayment";

use([PieChart]);

const props = defineProps({
  selectedCashRegister: {
    type: Object,
    required: true,
  },
  formatAmount: {
    type: Function,
    required: true,
  },
});

const depositsPieChartOptions = computed(() => {
  const depositsByMethod = groupDepositsByMethod(
    props.selectedCashRegister.deposits,
  );
  const actualPaymentsByMethod = groupPaymentsByMethod(
    props.selectedCashRegister.payments,
  );
  Object.entries(actualPaymentsByMethod).forEach(([methodId, amount]) => {
    if ([PaymentMethod.CreditCard].includes(Number(methodId))) {
      if (!depositsByMethod[methodId]) {
        depositsByMethod[methodId] = 0;
      }

      depositsByMethod[methodId] += amount;
    }
  });
  let depositsTotal = Object.values(depositsByMethod).reduce(
    (sum, deposit) => sum + deposit,
    0,
  );
  // Format for chart
  const pieData = Object.entries(depositsByMethod).map(([methodId, amount]) => {
    return {
      name: getPaymentMethodName(methodId),
      value: amount,
    };
  });

  return {
    title: {
      text: `Total: ${props.formatAmount(depositsTotal)}`,
      left: "center",
      top: "bottom",
      textStyle: {
        fontSize: 14,
        fontWeight: "normal",
      },
    },
    tooltip: {
      trigger: "item",
      formatter: (params) => {
        return `${params.name}: ${props.formatAmount(params.value)} (${params.percent}%)`;
      },
    },
    legend: {
      orient: "vertical",
      left: "left",
      type: "scroll",
    },
    series: [
      {
        name: "Dépôts",
        type: "pie",
        radius: "65%",
        center: ["50%", "50%"],
        data: pieData,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        label: {
          formatter: "{b}: {d}%",
        },
        itemStyle: {
          // Custom colors for each payment method
          color: (params) => {
            return getPaymentMethodColor(
              Object.keys(depositsByMethod)[params.dataIndex],
            );
          },
        },
      },
    ],
    graphic:
      pieData.length === 0
        ? [
          {
            type: "text",
            left: "center",
            top: "middle",
            style: {
              text: "Aucun dépôt",
              color: "black",
              fill: "#999",
              fontSize: 14,
            },
          },
        ]
        : undefined,
  };
});
</script>
