<template>
  <div class="cash-register-dashboard">
    <!-- Loading state -->
    <div v-if="loading" class="loading-container">
      <AtomsLoadingPanel>
        <div class="loading-text">Chargement des données en cours...</div>
      </AtomsLoadingPanel>
    </div>

    <template v-else>
      <!-- City and cash register selection -->
      <div class="grid gap-4 sm:grid-cols-1 md:grid-cols-[1fr_1fr] lg:grid-cols-[minmax(150px,_1fr)_1fr]">
        <div class="flex flex-col">
          <h2 class="p-4 font-bold bg-white rounded-t-lg">Villes</h2>
          <Listbox v-if="cities" v-model="selectedCity" key="cities" :options="cities" optionLabel="name"
            option-value="name" :pt="{
              root: { class: 'border-none rounded-t-none' },
            }" @change="onCityChange" filter>
            <template #option="slotProps">
              <div class="flex gap-2 items-center">
                <div>{{ slotProps.option.name }}</div>
              </div>
            </template>
          </Listbox>
        </div>

        <div class="flex flex-col">
          <h2 class="p-4 font-bold bg-white rounded-t-lg">
            Historique des caisses
          </h2>
          <Listbox key="cashRegister" v-if="cities && cashRegistersForCity.length > 0" v-model="selectedCashRegister"
            :options="cashRegistersForCity" optionLabel="label" :pt="{
              root: { class: 'border-none rounded-t-none' },
            }">
            <template #option="slotProps">
              <div class="flex gap-2 items-center">
                <div>{{ slotProps.option.label }}</div>
                <Tag v-if="slotProps.option.origin" :value="slotProps.option.origin" />
              </div>
            </template>
          </Listbox>
          <div v-else-if="selectedCity" class="p-4 bg-white">
            Aucune caisse disponible pour cette ville
          </div>
        </div>
      </div>

      <div class="mt-4 mb-4" v-if="selectedCashRegister">
        <div class="stats-grid">
          <div class="stat-card primary-stat">
            <div class="stat-value">
              {{ formatAmount(getTotalDeposits()) }}
            </div>

            <div class="stat-label">Total des dépôts</div>
          </div>

          <div class="stat-card success-stat">
            <div class="stat-value">
              {{ formatAmount(getTotalPayments()) }}
            </div>

            <div class="stat-label">Total des encaissements</div>
          </div>

          <div class="stat-card info-stat">
            <div class="stat-value">
              {{ selectedCashRegister.payments?.length || 0 }}
            </div>

            <div class="stat-label">Nombre de encaissements</div>
          </div>

          <div class="stat-card" :class="getBalanceClass(selectedCashRegister.open_cash_balance)">
            <div class="stat-value">
              {{ selectedCashRegister.open_cash_balance }}
            </div>

            <div class="stat-label">Écart à l'ouverture</div>
          </div>

          <div class="stat-card" :class="getBalanceClass(selectedCashRegister.close_cash_balance)"
            v-if="selectedCashRegister.close_cash_balance != undefined">
            <div class="stat-value">
              {{ selectedCashRegister.close_cash_balance }}
            </div>

            <div class="stat-label">Écart à la fermeture</div>
          </div>
        </div>
      </div>

      <!-- Charts section -->
      <div class="grid gap-4 mt-4 md:grid-cols-1 lg:grid-cols-2" v-if="selectedCashRegister">
        <FinancialOverviewChart :selected-cash-register :format-amount="formatAmount" />
        <div class="grid gap-4 mt-4 md:grid-cols-1 lg:grid-cols-2" v-if="selectedCashRegister">
          <DepositsPieChart :selectedCashRegister :format-amount="formatAmount" />
          <PaymentsPieChart :format-amount="formatAmount" :selected-cash-register />
        </div>
      </div>

      <!-- City overview chart -->
      <div class="mt-4" v-if="
        selectedCity &&
        cashRegistersForCity.length > 0 &&
        !selectedCashRegister
      ">
        <CityOverviewChart :selectedCity :cash-registers-for-city :format-amount="formatAmount" />
      </div>

      <div class="mt-4" v-if="selectedCashRegister">
        <Tabs :items="detailedTableTabs" defaultPosition="0">
          <template #deposits>
            <DataTable :value="selectedCashRegister.deposits" responsiveLayout="scroll" :paginator="selectedCashRegister.deposits &&
              selectedCashRegister.deposits.length > 5
              " :rows="5">
              <Column field="slip_number" header="N° de bordereau"></Column>
              <Column field="nb_cheque" header="Nb. chèques"></Column>
              <Column field="amount_ttc" header="Montant" :body="amountBodyTemplate"></Column>
              <Column field="payment_method.name" header="Moyen de paiement" />
              <Column field="deposit_date" header="Date" :body="dateBodyTemplate"></Column>
            </DataTable>
          </template>

          <template #payments>
            <DetailedPaymentsTable :payments="selectedCashRegister.payments" :format-amount="formatAmount"
              :format-date="formatDate" :payment-method-names="paymentMethodNames" />
          </template>
        </Tabs>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted, onUnmounted, nextTick } from "vue";
import { use } from "echarts/core";
import { BarChart, PieChart, LineChart } from "echarts/charts";

import DetailedPaymentsTable from "~/components/placer/dashboard/cashes/payments-table/Index.vue";
import {
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  DataZoomComponent,
  GraphicComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import CityOverviewChart from "./charts/CityOverviewChart.vue";
import DepositsPieChart from "./charts/DepositsPieChart.vue";
import PaymentsPieChart from "./charts/PaymentsPieChart.vue";
import FinancialOverviewChart from "./charts/FinancialOverviewChart.vue";
import Tabs from "~/components/atoms/Tabs.vue";
// Register ECharts components
use([
  CanvasRenderer,
  BarChart,
  PieChart,
  LineChart,
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  DataZoomComponent,
  GraphicComponent,
]);

// Props
interface Props {
  placerId: string;
}
const { placerId } = defineProps<Props>();

// State
const loading = ref(true);
const selectedCity = ref("");
const selectedCashRegister = ref(null);
const detailedTableTabs = [
  { header: "Dépôts", value: "deposits", disabled: null },
  { header: "Encaissements", value: "payments", disabled: null },
];
const { formatDate } = useDateFormatter();
const { formatAmount } = useAmountFormatter();
const { data: cashRegisters } = useLazySanctumFetch(
  `/api/placers/${placerId}/cash-registers`,
);

const cashRegisterHistories = computed(() => {
  let results = new Map();

  if (!cashRegisters.value) {
    return results;
  }

  for (let i = 0; i < cashRegisters.value.length; i++) {
    const currentRegister = cashRegisters.value[i];
    const city = currentRegister.city.name;

    if (!results.has(city)) {
      results.set(city, []);
    }

    for (
      let j = 0;
      j < currentRegister.cash_register_histories.length;
      j += 2
    ) {
      let openingCashRegisterHistory =
        currentRegister.cash_register_histories[j];

      let closingCashRegisterHistory =
        currentRegister.cash_register_histories[j + 1];
      let deposits = currentRegister.deposits.filter((deposit) => {
        return (
          deposit.cash_register_history_id === openingCashRegisterHistory?.id ||
          deposit.cash_register_history_id === closingCashRegisterHistory?.id
        );
      });

      let payments = currentRegister.payments.filter((payment) => {
        return (
          payment.cash_register_history_id === openingCashRegisterHistory?.id ||
          payment.cash_register_history_id === closingCashRegisterHistory?.id
        );
      });
      let label = "";
      if (openingCashRegisterHistory && closingCashRegisterHistory) {
        label = `Du ${formatDate(openingCashRegisterHistory.created_at)} au ${formatDate(closingCashRegisterHistory.created_at)}`;
      } else {
        label = `Depuis le ${formatDate(openingCashRegisterHistory.created_at)}`;
      }
      let obj = {
        label: label,
        origin: currentRegister.origin,
        deposits: deposits,
        open_cash_balance:
          openingCashRegisterHistory?.balance_cash_difference ?? 0,
        close_cash_balance:
          closingCashRegisterHistory?.balance_cash_difference ?? 0,
        payments: payments,
      };

      const cityRegisters = results.get(city);
      cityRegisters.push(obj);
    }
  }

  return results;
});

const cities = computed(() => {
  const cityList = [];

  if (!cashRegisters.value) {
    return [];
  }

  cashRegisters.value.forEach((cashRegister) => {
    if (!cityList.find((city) => city.name === cashRegister.city.name)) {
      cityList.push({
        name: cashRegister.city.name,
        value: cashRegister.city.name,
      });
    }
  });

  return cityList;
});

// Computed property to get cash registers for the selected city
const cashRegistersForCity = computed(() => {
  if (
    !selectedCity.value ||
    !cashRegisterHistories.value.has(selectedCity.value)
  ) {
    return [];
  }

  const registers = cashRegisterHistories.value.get(selectedCity.value);
  return registers;
});

const amountBodyTemplate = (rowData) => {
  return formatAmount(rowData.amount_ttc || rowData.amount);
};

const dateBodyTemplate = (rowData) => {
  const dateValue =
    rowData.deposit_date || rowData.value_date || rowData.created_at;
  return formatDate(dateValue);
};

// Calculation methods
const getTotalDeposits = () => {
  if (!selectedCashRegister.value || !selectedCashRegister.value.deposits) {
    return 0;
  }
  return selectedCashRegister.value.deposits.reduce(
    (sum, deposit) => sum + parseFloat(deposit.amount_ttc || 0),
    0,
  );
};

const getTotalPayments = () => {
  if (!selectedCashRegister.value || !selectedCashRegister.value.payments) {
    return 0;
  }
  return selectedCashRegister.value.payments.reduce(
    (sum, payment) => sum + parseFloat(payment.amount || 0),
    0,
  );
};

const getBalance = () => {
  return getTotalDeposits() - getTotalPayments();
};

const getBalanceClass = (value) => {
  const balance = value ?? getBalance();
  if (balance > 0) return "success-stat";
  if (balance < 0) return "danger-stat";
  return "info-stat";
};

const paymentMethodNames = {
  1: "Espèces",
  2: "Chèque",
  3: "Carte bancaire",
  4: "Virement",
  5: "Prélèvement",
};

const getPaymentMethodName = (methodId) => {
  return paymentMethodNames[methodId] || `Méthode ${methodId}`;
};

// Event handlers
const onCityChange = () => {
  selectedCashRegister.value = null;
  nextTick(() => {
    loading.value = false;
  });
};

// Initialize data
onMounted(() => {
  loading.value = true;
});

watch(cashRegisters, (newVal) => {
  if (newVal) {
    nextTick(() => {
      loading.value = false;
    });
  }
});
</script>

<style scoped>
.cash-register-dashboard {
  padding: 0.5rem;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background: var(--surface-ground);
  border-radius: 4px;
  height: 100%;
}

.loading-text {
  margin-top: 0.75rem;
  color: var(--text-color-secondary);
}

/* Stats grid */
.stats-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.stat-card {
  flex: 1;
  min-width: 120px;
  padding: 0.75rem;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.stat-value {
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 0.25rem;
}

.stat-label {
  font-size: 0.8rem;
  color: var(--text-color-secondary);
  line-height: 1.2;
}

.primary-stat {
  background: rgba(var(--primary-rgb, 59, 130, 246), 0.1);
  border: 1px solid var(--primary-300);
}

.primary-stat .stat-value {
  color: var(--primary-700);
}

.success-stat {
  background: rgba(34, 197, 94, 0.1);
  border: 1px solid rgba(34, 197, 94, 0.3);
}

.success-stat .stat-value {
  color: #1e4620;
}

.warning-stat {
  background: rgba(245, 158, 11, 0.1);
  border: 1px solid rgba(245, 158, 11, 0.3);
}

.warning-stat .stat-value {
  color: #9a3412;
}

.danger-stat {
  background: rgba(239, 68, 68, 0.1);
  border: 1px solid rgba(239, 68, 68, 0.3);
}

.danger-stat .stat-value {
  color: #991b1b;
}

.info-stat {
  background: rgba(59, 130, 246, 0.1);
  border: 1px solid rgba(59, 130, 246, 0.3);
}

.info-stat .stat-value {
  color: #1e40af;
}

/* Chart styles */
.chart-card {
  background: var(--surface-card);
  border-radius: 4px;
  padding: 1rem;
  box-shadow:
    0 2px 1px -1px rgba(0, 0, 0, 0.1),
    0 1px 1px 0 rgba(0, 0, 0, 0.07),
    0 1px 3px 0 rgba(0, 0, 0, 0.06);
}

.chart-title {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: var(--text-color);
}

.chart-container {
  height: 350px;
  width: 100%;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .stats-grid {
    flex-direction: column;
  }

  .stat-card {
    width: 100%;
  }

  .chart-container {
    height: 250px;
  }
}
</style>
