<template>
  <BaseChart :title="`Vue d'ensemble - ${selectedCity}`" :chart-options="cityChartOptions" :loading="isLoading">
    <template #pre-chart>
      <div v-if="cashRegistersForCity.length > 10" class="zoom-hint">
        <small>
          <i class="pi pi-info-circle"></i>
          Utilisez le curseur en bas du graphique pour naviguer dans les données
        </small>
      </div>
    </template>
  </BaseChart>
</template>

<script setup lang="ts">
import { computed, ref, watch, onMounted } from "vue";
import { LineChart, BarChart } from "echarts/charts";
import {
  TooltipComponent,
  DataZoomComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
import { use } from "echarts/core";
import BaseChart from "./BaseChart.vue";
import {
  allPaymentMethodIds,
  groupDepositsByMethod,
  groupPaymentsByMethod,
} from "~/utils/paymentMethod";

use([
  BarChart,
  LineChart,
  TooltipComponent,
  DataZoomComponent,
  LegendComponent,
  GridComponent,
]);
const props = defineProps({
  selectedCity: {
    type: String,
    required: true,
  },
  cashRegistersForCity: {
    type: Array,
    required: true,
  },
  formatAmount: {
    type: Function,
    required: true,
  },
});

// Add loading state
const isLoading = ref(true);

// Cached data and processing
const processedData = ref({
  labels: [],
  depositData: [],
  paymentData: [],
  maxValue: 0,
  maxNormalValue: 0,
  upperOutlierThreshold: 0,
  hasOutliers: false,
});

// Process data efficiently when inputs change
watch(
  () => [props.cashRegistersForCity, props.selectedCity],
  async () => {
    // Show loading state
    isLoading.value = true;

    // Use setTimeout to allow UI to update
    setTimeout(async () => {
      try {
        await processData(props.cashRegistersForCity);
      } finally {
        // Hide loading state
        isLoading.value = false;
      }
    }, 0);
  },
  { immediate: true },
);

// Efficient data processing with web worker-like approach
async function processData(registers) {
  if (!registers || registers.length === 0) {
    processedData.value = {
      labels: [],
      depositData: [],
      paymentData: [],
      maxValue: 0,
      maxNormalValue: 0,
      upperOutlierThreshold: 0,
      hasOutliers: false,
    };
    return;
  }

  // Process data - split into chunks for large datasets
  const batchSize = registers.length > 100 ? 50 : registers.length;
  const labels = [];
  const depositData = [];
  const paymentData = [];

  // Process data in chunks to avoid blocking UI
  for (let i = 0; i < registers.length; i += batchSize) {
    const chunk = registers.slice(i, i + batchSize);

    // Allow UI thread to breathe between chunks
    if (i > 0) {
      await new Promise((resolve) => setTimeout(resolve, 0));
    }

    // Process this chunk
    chunk.forEach((reg) => {
      labels.push(reg.label);

      // Sum deposits
      const depositSum = reg.deposits
        ? reg.deposits.reduce(
          (sum, dep) => sum + parseFloat(dep.amount_ttc || 0),
          0,
        )
        : 0;
      depositData.push(depositSum);

      // Sum payments
      const paymentSum = reg.payments
        ? reg.payments.reduce(
          (sum, pay) => sum + parseFloat(pay.amount || 0),
          0,
        )
        : 0;
      paymentData.push(paymentSum);
    });
  }

  // Calculate statistics
  const allValues = [...depositData, ...paymentData].filter((v) => v > 0);

  if (allValues.length > 0) {
    const sortedValues = [...allValues].sort((a, b) => a - b);

    // Find outliers using IQR
    const q1Index = Math.floor(sortedValues.length * 0.25);
    const q3Index = Math.floor(sortedValues.length * 0.75);

    const q1 = sortedValues[q1Index];
    const q3 = sortedValues[q3Index];
    const iqr = q3 - q1;
    const upperOutlierThreshold = q3 + iqr * 1.5;

    // Find maximum values (normal and outliers)
    const normalValues = sortedValues.filter((v) => v <= upperOutlierThreshold);
    const maxNormalValue = normalValues.length
      ? normalValues[normalValues.length - 1]
      : 0;
    const maxValue = sortedValues[sortedValues.length - 1];

    // Update processed data
    processedData.value = {
      labels,
      depositData,
      paymentData,
      maxValue,
      maxNormalValue,
      upperOutlierThreshold,
      hasOutliers: maxValue > maxNormalValue * 1.5,
    };
  } else {
    processedData.value = {
      labels,
      depositData,
      paymentData,
      maxValue: 0,
      maxNormalValue: 0,
      upperOutlierThreshold: 0,
      hasOutliers: false,
    };
  }
}

// Chart options as a computed property using processed data
const cityChartOptions = computed(() => {
  const {
    labels,
    depositData,
    paymentData,
    maxValue,
    maxNormalValue,
    upperOutlierThreshold,
    hasOutliers,
  } = processedData.value;

  // Calculate zoom settings
  const endPercent = Math.min(
    100,
    Math.max(50, (400 / Math.max(1, labels.length)) * 100),
  );

  // Create chart options
  return {
    // Improve loading appearance
    loadingOption: {
      text: "Chargement des données...",
      color: "#409EFF",
      textColor: "#000",
      maskColor: "rgba(255, 255, 255, 0.8)",
      zlevel: 0,
    },
    // Core chart options
    tooltip: {
      trigger: "axis",
      axisPointer: { type: "shadow" },
      formatter: (params) => {
        let result = `${params[0].name}<br/>`;
        params.forEach((param) => {
          result += `${param.marker} ${param.seriesName}: ${props.formatAmount(param.value)}<br/>`;
        });
        return result;
      },
    },
    legend: {
      data: ["Dépôts", "Encaissements", "Différence"],
      bottom: 30,
      type: "scroll",
      selected: {
        Dépôts: true,
        Encaissements: true,
        Différence: false, // Off by default for better performance
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "15%",
      top: "10%",
      containLabel: true,
    },
    dataZoom: [
      {
        type: "slider",
        show: true,
        start: 0,
        end: endPercent,
        height: 20,
        bottom: 0,
        throttle: 100, // Improve performance
      },
      {
        type: "inside",
        start: 0,
        end: endPercent,
        throttle: 100,
      },
    ],
    xAxis: {
      type: "category",
      data: labels,
      axisLabel: {
        interval: "auto",
        rotate: 45,
        hideOverlap: true,
        formatter: (value) => {
          return value.length > 15 ? value.substring(0, 12) + "..." : value;
        },
      },
    },
    yAxis: {
      type: "value",
      axisLabel: {
        formatter: (value) => props.formatAmount(value).replace(" €", ""),
      },
      // Handle outliers
      max: hasOutliers ? maxNormalValue * 1.2 : null,
      scale: true,
    },
    series: [
      {
        name: "Dépôts",
        type: "bar",
        data: depositData.map((value, index) => {
          if (value > upperOutlierThreshold) {
            return {
              value: value,
              itemStyle: {
                color: "#67C23A",
                opacity: 0.8,
              },
              label: {
                show: true,
                position: "top",
                formatter: props.formatAmount(value).replace(" €", ""),
                fontSize: 10,
              },
            };
          }
          return value;
        }),
        itemStyle: { color: "#67C23A" },
        // Performance optimizations
        progressive: 300,
        progressiveThreshold: 500,
        large: true,
        largeThreshold: 200,
        markLine:
          labels.length < 50
            ? {
              silent: true,
              data: [{ type: "average", name: "Moyenne" }],
            }
            : undefined,
      },
      {
        name: "Encaissements",
        type: "bar",
        data: paymentData.map((value, index) => {
          if (value > upperOutlierThreshold) {
            return {
              value: value,
              itemStyle: {
                color: "#409EFF",
                opacity: 0.8,
              },
              label: {
                show: true,
                position: "top",
                formatter: props.formatAmount(value).replace(" €", ""),
                fontSize: 10,
              },
            };
          }
          return value;
        }),
        itemStyle: { color: "#409EFF" },
        // Performance optimizations
        progressive: 300,
        progressiveThreshold: 500,
        large: true,
        largeThreshold: 200,
        markLine:
          labels.length < 50
            ? {
              silent: true,
              data: [{ type: "average", name: "Moyenne" }],
            }
            : undefined,
      },
      {
        name: "Différence",
        type: "line",
        data: depositData.map(
          (depValue, index) => depValue - (paymentData[index] || 0),
        ),
        lineStyle: { type: "dashed", width: 2 },
        symbol: "circle",
        symbolSize: 5,
        sampling: "average",
        itemStyle: {
          color: (params) => {
            const value = params.value;
            return value > 0 ? "#67C23A" : value < 0 ? "#F56C6C" : "#909399";
          },
        },
      },
    ],
  };
});

// Clean up on unmount
onMounted(() => {
  return () => {
    processedData.value = {
      labels: [],
      depositData: [],
      paymentData: [],
      maxValue: 0,
      maxNormalValue: 0,
      upperOutlierThreshold: 0,
      hasOutliers: false,
    };
  };
});
</script>
