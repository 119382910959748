<template>
  <Tabs v-model:value="tabPosition" class="dark:border-none dark:bg-surface-800 dark:text-primary-50" v-bind="$attrs">
    <TabList>
      <Tab v-for="tab in items" :key="tab.value" :value="tab.value" :disabled="tab?.disabled">{{ tab.header }}</Tab>
    </TabList>
    <TabPanels class="bg-transparent">
      <TabPanel v-for="tab in items" :value="tab.value">
        <slot :name="tab.value" />
      </TabPanel>
    </TabPanels>
  </Tabs>
</template>

<script setup lang="ts">
import Tabs from "primevue/tabs";
export interface TabItem {
  header: string;
  value: string;
  disabled: boolean | null;
}
interface Props {
  defaultPosition: string | number;
  items: Array<TabItem>;
}
const route = useRoute();
const props = defineProps<Props>();
const defaultPosition = computed(() => {
  if (
    props.defaultPosition !== undefined &&
    props.defaultPosition <= props.items.length
  ) {
    return props.defaultPosition;
  }

  for (let i = 0; i < props.items.length; i++) {
    if (props.items[i] && !props.items[i].disabled) {
      return i;
    }
  }

  return 0;
});
const tabPosition = ref(props.items[defaultPosition?.value]?.value);

const gotoTab = (to) => {
  if (props.items.some((tab) => tab.value == to) && tabPosition?.value) {
    tabPosition.value = to;
  }
};
watch(
  () => route.query,
  (newQuery) => {
    if (newQuery?.tab) {
      gotoTab(newQuery.tab);
    }
  },
  { immediate: true },
);
</script>
