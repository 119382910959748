const paymentMethodNames = {
  1: "Espèces",
  2: "Chèque",
  3: "Prélèvements Bancaires",
  4: "Virement",
  5: "Carte bancaire",
};

const paymentMethodColors = {
  1: "#67C23A", // Green for cash
  2: "#409EFF", // Blue for checks
  3: "#E6A23C", // Orange for credit cards
  4: "#F56C6C", // Red for bank transfers
  5: "#909399", // Grey for direct debits
};

const allPaymentMethodIds = () => Object.keys(paymentMethodNames);

const getPaymentMethodName = (methodId: string | number) =>
  paymentMethodNames[methodId] || `Méthode ${methodId}`;
const getPaymentMethodColor = (methodId: string | number) =>
  paymentMethodColors[methodId] || "#909399";

const groupPaymentsByMethod = (payments: any[]) => {
  const paymentsByMethod = {};
  payments?.forEach((payment) => {
    const methodId = payment.payment_method_id;
    if (!paymentsByMethod[methodId]) {
      paymentsByMethod[methodId] = 0;
    }
    paymentsByMethod[methodId] += parseFloat(payment.amount || 0);
  });
  return paymentsByMethod;
};

const groupDepositsByMethod = (deposits: any[]) => {
  const depositsByMethod = {};
  deposits?.forEach((deposit) => {
    const methodId = deposit.payment_method_id;
    if (!depositsByMethod[methodId]) {
      depositsByMethod[methodId] = 0;
    }
    depositsByMethod[methodId] += parseFloat(deposit.amount_ttc || 0);
  });
  return depositsByMethod;
};

interface Payment {
  payment_method_id: number;
  amount: number;
}

interface Deposit {
  payment_method_id: number;
  amount_ttc: number;
}
export {
  type Deposit,
  type Payment,
  paymentMethodNames,
  paymentMethodColors,
  allPaymentMethodIds,
  getPaymentMethodName,
  getPaymentMethodColor,
  groupPaymentsByMethod,
  groupDepositsByMethod,
};
