<script setup lang="ts">
import Role from "~/types/Role";
import Permissions from "~/types/Permissions";
import AccountManagement from "~/components/placer/dashboard/AccountManagement.vue";
import Cashes from "~/components/placer/dashboard/cashes/Index.vue";
definePageMeta({
  roles: [Role.ADMIN, Role.SUPER_ADMIN, Role.HEAD_OF_OPERATION, Role.MANAGER],
  permissions: [Permissions.ViewPlacer],
});
const tabsItems = [
  {
    header: "Géneral",
    value: "main",
    disabled: true,
  },
  {
    header: "Gestion des comptes",
    value: "account-management",
  },
  {
    header: "Caisses",
    value: "cashes",
  },
];

const route = useRoute();
const client = useSanctumClient();
const { data: placer } = await useLazyAsyncData(
  `placer-dashboard-${route.params.id}`,
  () =>
    client(
      `/api/placers/${route.params.id}?include=markets,markets.billingPeriod,markets.city`,
    ),
);
</script>
<template>
  <div class="flex w-full bg-white border-b border-gray-200 dark:bg-surface-700">
    <AtomsNavLink class="border-none w-fit" :navLinks="[
      { route: '/placers', name: 'Placiers' },
      {
        route: `/placers/show/${$route.params.id}`,
        name: placer?.data ? placer?.data.name : '',
      },
    ]" />
  </div>

  <AtomsTabs :items="tabsItems" :lazy="true">
    <template #account-management>
      <AccountManagement v-if="placer" />
    </template>
    <template #cashes>
      <Cashes :placer-id="route.params.id" />
    </template>
  </AtomsTabs>
</template>
