<template>
  <div class="detailed-payments-table">
    <DataTable :value="dateGroups" dataKey="date_key" rowGroupMode="subheader" groupRowsBy="date_key" sortMode="single"
      sortField="date_key" :sortOrder="-1" v-model:expandedRowGroups="expandedRowGroups" expandableRowGroups
      class="p-datatable-sm" tableStyle="min-width: 50rem" emptyMessage="Aucun encaissement disponible" :pt="{
        rowGroupHeaderCell: {
          class: 'flex items-center',
        },
      }">
      <template #groupheader="slotProps">
        <div class="flex justify-between items-center py-2 px-4 w-full">
          <span class="text-lg font-medium">{{ slotProps.data.date_key }}</span>
          <span class="text-lg font-bold">{{
            formatAmount(slotProps.data.total)
            }}</span>
        </div>
      </template>

      <!-- Table Headers -->
      <Column field="date_key" header="Type" style="width: 30%"></Column>
      <Column header="Details">
        <template #body="slotProps">
          <!-- Only render markets if the date group is expanded -->
          <div v-if="isDateExpanded(slotProps.data.date_key)" class="p-4 date-expansion">
            <!-- Debug info to verify data -->
            <div v-if="Object.keys(slotProps.data.markets || {}).length === 0" class="p-4 text-center text-gray-500">
              Aucune donnée disponible pour cette date
            </div>

            <MarketsSection :markets="slotProps.data.markets" :formatAmount="formatAmount" :formatDate="formatDate" />
          </div>
        </template>
      </Column>

      <template #groupfooter="slotProps">
        <td colspan="2" class="p-2 font-bold text-right">
          Total: {{ formatAmount(slotProps.data.total) }}
        </td>
      </template>
    </DataTable>
  </div>
</template>

<script setup>
import {
  computed,
  onMounted,
  defineAsyncComponent,
  markRaw,
  shallowRef,
} from "vue";

const MarketsSection = defineAsyncComponent(
  () => import("./sections/MarketsSection.vue"),
);

// Props
const props = defineProps({
  payments: {
    type: Array,
    default: () => [],
  },
  formatAmount: {
    type: Function,
    required: true,
  },
  formatDate: {
    type: Function,
    required: true,
  },
});

const expandedRowGroups = shallowRef([]);

const isDateExpanded = (dateKey) => {
  return expandedRowGroups.value.includes(dateKey);
};

const processedPayments = computed(() => {
  return (
    props.payments?.map((payment, index) => ({
      ...payment,
      id: payment.id || `payment_${index}`,
      date_key: payment.value_date
        ? formatDateKey(payment.value_date)
        : "Sans date",
      market_name:
        payment.invoice_payments?.[0]?.invoice?.market_name || "Sans marché",
      merchant_type:
        payment.invoice_payments?.[0]?.invoice?.invoice_type_id === 1
          ? "floating"
          : payment.invoice_payments?.[0]?.invoice?.invoice_type_id === 2
            ? "subscriber"
            : "undefined",
      amount: parseFloat(payment.amount || 0),
    })) || []
  );
});

const paymentsByDate = computed(() => {
  const result = {};

  processedPayments.value.forEach((payment) => {
    if (!result[payment.date_key]) {
      result[payment.date_key] = {
        date_key: payment.date_key,
        total: 0,
        markets: {},
      };
    }

    result[payment.date_key].total += payment.amount;

    const marketName = payment.market_name;
    if (!result[payment.date_key].markets[marketName]) {
      result[payment.date_key].markets[marketName] = {
        name: marketName,
        key: `${payment.date_key}_${marketName}`,
        total: 0,
        merchantTypes: {},
      };
    }

    result[payment.date_key].markets[marketName].total += payment.amount;

    const merchantType = payment.merchant_type;
    if (
      !result[payment.date_key].markets[marketName].merchantTypes[merchantType]
    ) {
      result[payment.date_key].markets[marketName].merchantTypes[merchantType] =
      {
        type: merchantType,
        total: 0,
        paymentMethods: {},
      };
    }

    result[payment.date_key].markets[marketName].merchantTypes[
      merchantType
    ].total += payment.amount;

    const methodId = payment.payment_method_id;
    if (
      !result[payment.date_key].markets[marketName].merchantTypes[merchantType]
        .paymentMethods[methodId]
    ) {
      result[payment.date_key].markets[marketName].merchantTypes[
        merchantType
      ].paymentMethods[methodId] = {
        id: methodId,
        total: 0,
        payments: [],
      };
    }

    result[payment.date_key].markets[marketName].merchantTypes[
      merchantType
    ].paymentMethods[methodId].total += payment.amount;

    result[payment.date_key].markets[marketName].merchantTypes[
      merchantType
    ].paymentMethods[methodId].payments.push(payment);
  });

  return markRaw(result);
});

const dateGroups = computed(() => {
  const groups = Object.values(paymentsByDate.value);
  groups.sort((a, b) => {
    const dateA = new Date(parseSimpleDate(a.date_key));
    const dateB = new Date(parseSimpleDate(b.date_key));
    return dateB - dateA;
  });
  return groups;
});

const dateCache = new Map();
const parseSimpleDate = (dateStr) => {
  if (dateCache.has(dateStr)) {
    return dateCache.get(dateStr);
  }

  const months = {
    janvier: 0,
    février: 1,
    mars: 2,
    avril: 3,
    mai: 4,
    juin: 5,
    juillet: 6,
    août: 7,
    septembre: 8,
    octobre: 9,
    novembre: 10,
    décembre: 11,
  };

  try {
    const parts = dateStr.split(" ");
    if (parts.length === 3) {
      const day = parseInt(parts[0]);
      const month = months[parts[1]];
      const year = parseInt(parts[2]);
      const date = new Date(year, month, day);
      dateCache.set(dateStr, date);
      return date;
    }
  } catch (e) {
    console.error("Error parsing date:", e);
  }

  const defaultDate = new Date();
  dateCache.set(dateStr, defaultDate);
  return defaultDate;
};

const formatDateKey = (date) => {
  if (!date) return "";
  return props.formatDate(date);
};

onMounted(() => {
  if (dateGroups.value.length > 0) {
    expandedRowGroups.value = [dateGroups.value[0].date_key];
  }
});
</script>

<style scoped>
.detailed-payments-table {
  width: 100%;
}

:deep(.p-datatable .p-datatable-thead > tr > th) {
  background-color: #f8f9fa;
  color: #495057;
  font-weight: 600;
  padding: 0.75rem 1rem;
  border-bottom: 1pt solid var(--surface-200);
}

:deep(.p-datatable-tbody) tr.p-rowgroup-header {
  background-color: var(--surface-100);
  font-weight: 600;
  cursor: pointer;
}

:deep(.p-datatable-tbody) tr.p-rowgroup-header:hover {
  background-color: var(--surface-200);
}

:deep(.p-datatable-tbody) tr.p-rowgroup-header>td {
  border-width: 0;
  padding: 0;
}

:deep(.p-datatable-tbody) tr.p-rowgroup-header>td>div.p-rowgroup-toggler {
  vertical-align: middle;
}

:deep(.p-datatable-tbody) tr.p-rowgroup-footer>td {
  border-width: 0;
  border-top: 1pt solid var(--surface-200);
  font-weight: 600;
}

.date-expansion {
  background-color: var(--surface-0);
  border-radius: 0.25rem;
}
</style>
