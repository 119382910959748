// Generated by nuxt-echarts

import { use } from 'echarts/core'
import {
  CanvasRenderer,
} from 'echarts/renderers'
import {

} from 'echarts/charts'
import {

} from 'echarts/components'
import {

} from 'echarts/features'

use([
  CanvasRenderer,



])