<template>
  <div>
    <BaseChart title="Aperçu financier" :chart-options="barChartOptions" autoresize>
      <template #pre-chart>
        <div class="flex mb-2 justify-content-between">
          <Button v-if="hasMultiplePaymentMethods" @click="toggleEcartDetails" :label="showDetailedEcarts
              ? 'Afficher écart total'
              : 'Afficher écarts détaillés'
            " size="small" outline />
        </div>
      </template>
    </BaseChart>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { BarChart, LineChart } from "echarts/charts";
import { use } from "echarts/core";
import BaseChart from "./BaseChart.vue";
import { GraphicComponent, TitleComponent } from "echarts/components";
import {
  getPaymentMethodName,
  groupDepositsByMethod,
  groupPaymentsByMethod,
  allPaymentMethodIds,
} from "~/utils/paymentMethod";
import Button from "primevue/button";
import { PaymentMethod } from "~/types/MethodPayment";

use([BarChart, LineChart, TitleComponent, GraphicComponent]);

const props = defineProps({
  selectedCashRegister: {
    type: Object,
    required: true,
  },
  formatAmount: {
    type: Function,
    required: true,
  },
});

// State to toggle between detailed écarts and total écart
const showDetailedEcarts = ref(false);
const toggleEcartDetails = () => {
  showDetailedEcarts.value = !showDetailedEcarts.value;
};

const barChartOptions = computed(() => {
  // Get data by method
  const actualDepositsByMethod = groupDepositsByMethod(
    props.selectedCashRegister.deposits,
  );
  const actualPaymentsByMethod = groupPaymentsByMethod(
    props.selectedCashRegister.payments,
  );

  Object.entries(actualPaymentsByMethod).forEach(([methodId, amount]) => {
    if ([PaymentMethod.CreditCard].includes(Number(methodId))) {
      if (!actualDepositsByMethod[methodId]) {
        actualDepositsByMethod[methodId] = 0;
      }

      actualDepositsByMethod[methodId] += amount;
    }
  });

  const allMethodIds = [
    ...new Set([
      ...allPaymentMethodIds(),
      ...Object.keys(actualDepositsByMethod),
      ...Object.keys(actualPaymentsByMethod),
    ]),
  ].sort();

  const depositsByMethod = {};
  const paymentsByMethod = {};

  allMethodIds.forEach((methodId) => {
    depositsByMethod[methodId] = actualDepositsByMethod[methodId] || 0;
    paymentsByMethod[methodId] = actualPaymentsByMethod[methodId] || 0;
  });

  const totalDeposits = Object.values(depositsByMethod).reduce(
    (sum, value) => sum + parseFloat(value || 0),
    0,
  );
  const totalPayments = Object.values(paymentsByMethod).reduce(
    (sum, value) => sum + parseFloat(value || 0),
    0,
  );
  const totalEcart = totalDeposits - totalPayments;

  const activeMethodIds = allMethodIds.filter(
    (methodId) =>
      depositsByMethod[methodId] > 0 || paymentsByMethod[methodId] > 0,
  );

  const methodNames = activeMethodIds.map((id) => getPaymentMethodName(id));

  const depositsSeries = {
    name: "Dépôts",
    type: "bar",
    xAxisIndex: 0,
    yAxisIndex: 0,
    barGap: "0%",
    itemStyle: { color: "#67C23A" },
    data: activeMethodIds.map((methodId) => depositsByMethod[methodId] || 0),
    label: {
      show: true,
      position: "top",
      formatter: (params) => props.formatAmount(params.value).replace(" €", ""),
      fontSize: 10,
    },
  };

  const paymentsSeries = {
    name: "Encaissements",
    type: "bar",
    xAxisIndex: 0,
    yAxisIndex: 0,
    barGap: "0%",
    itemStyle: { color: "#409EFF" },
    data: activeMethodIds.map((methodId) => paymentsByMethod[methodId] || 0),
    label: {
      show: true,
      position: "top",
      formatter: (params) => props.formatAmount(params.value).replace(" €", ""),
      fontSize: 10,
    },
  };

  const ecartsByMethod = {};
  activeMethodIds.forEach((methodId) => {
    const diff =
      (depositsByMethod[methodId] || 0) - (paymentsByMethod[methodId] || 0);
    if (Math.abs(diff) >= 0.01) {
      ecartsByMethod[methodId] = diff;
    }
  });

  const ecartsMethodIds = Object.keys(ecartsByMethod);
  const ecartsMethodNames = ecartsMethodIds.map((id) =>
    getPaymentMethodName(id),
  );

  let ecartSeries = [];

  if (showDetailedEcarts.value && ecartsMethodIds.length > 0) {
    const detailedEcarts = ecartsMethodIds.map((methodId) => {
      const diff = ecartsByMethod[methodId];
      return {
        name: getPaymentMethodName(methodId),
        value: diff,
        itemStyle: {
          color: diff > 0 ? "#67C23A" : "#F56C6C",
          borderRadius: [3, 3, 0, 0],
        },
        label: {
          show: true,
          position: diff > 0 ? "top" : "bottom",
          formatter: () => {
            const sign = diff > 0 ? "+" : "";
            return `${sign}${props.formatAmount(diff)}`;
          },
          fontSize: 11,
          color: "#fff",
          backgroundColor: diff > 0 ? "#67C23A" : "#F56C6C",
          padding: [3, 6],
          borderRadius: 4,
        },
      };
    });

    ecartSeries = [
      {
        name: "Écarts détaillés",
        type: "bar",
        xAxisIndex: 1,
        yAxisIndex: 1,
        barWidth: "60%",
        data: detailedEcarts,
        // Don't show label in legend
        legendHoverLink: false,
      },
    ];
  } else {
    ecartSeries = [
      {
        name: "Écart total",
        type: "bar",
        xAxisIndex: 1,
        yAxisIndex: 1,
        barWidth: "50%",
        data: [
          {
            value: totalEcart,
            itemStyle: {
              color:
                totalEcart > 0
                  ? "#67C23A"
                  : totalEcart < 0
                    ? "#F56C6C"
                    : "#909399",
              borderRadius: [3, 3, 0, 0],
            },
          },
        ],
        label: {
          show: true,
          position: totalEcart >= 0 ? "top" : "bottom",
          formatter: () => {
            const sign = totalEcart > 0 ? "+" : "";
            return `${sign}${props.formatAmount(totalEcart)}`;
          },
          fontSize: 14,
          fontWeight: "bold",
          color: "#fff",
          backgroundColor:
            totalEcart > 0 ? "#67C23A" : totalEcart < 0 ? "#F56C6C" : "#909399",
          padding: [5, 10],
          borderRadius: 4,
        },
      },
    ];
  }

  // Calculate scaling values
  const maxValue =
    Math.max(
      ...Object.values(depositsByMethod),
      ...Object.values(paymentsByMethod),
      1,
    ) * 1.2; // Add 20% headroom

  // Max écart value for the right panel
  const maxEcart =
    Math.max(
      Math.abs(totalEcart),
      ...Object.values(ecartsByMethod).map((diff) => Math.abs(diff)),
      1,
    ) * 1.2; // Add 20% headroom

  return {
    tooltip: {
      trigger: "axis",
      axisPointer: { type: "shadow" },
      formatter: (params) => {
        // Left chart tooltip (payment methods)
        if (params[0].componentSubType === "bar" && params[0].axisIndex === 0) {
          const methodName = params[0].axisValue;
          let result = `<div style="font-weight:bold">${methodName}</div>`;

          // Find deposit and payment params
          const depositParam = params.find((p) => p.seriesName === "Dépôts");
          const paymentParam = params.find(
            (p) => p.seriesName === "Encaissements",
          );

          // Calculate écart for this method
          const methodId = activeMethodIds[params[0].dataIndex];
          const diff =
            (depositsByMethod[methodId] || 0) -
            (paymentsByMethod[methodId] || 0);

          // Show deposits and payments
          if (depositParam) {
            result += `${depositParam.marker} Dépôts: ${props.formatAmount(depositParam.value)}<br/>`;
          }

          if (paymentParam) {
            result += `${paymentParam.marker} Encaissements: ${props.formatAmount(paymentParam.value)}<br/>`;
          }

          // Show écart for this method
          if (Math.abs(diff) >= 0.01) {
            const sign = diff > 0 ? "+" : "";
            const color = diff > 0 ? "#67C23A" : "#F56C6C";

            result += `<div style="margin-top:5px;border-top:1px dashed #ddd;padding-top:5px;">`;
            result += `<span style="color:${color};font-weight:bold">Écart: ${sign}${props.formatAmount(diff)}</span>`;
            result += `</div>`;
          }

          return result;
        }
        // Right chart tooltip (écarts)
        else {
          if (showDetailedEcarts.value) {
            // Detailed écart view
            const methodName = params[0].name;
            const value = params[0].value;
            const sign = value > 0 ? "+" : "";
            const color = value > 0 ? "#67C23A" : "#F56C6C";

            let result = `<div style="font-weight:bold">Écart - ${methodName}</div>`;
            result += `<div style="color:${color};font-weight:bold">${sign}${props.formatAmount(value)}</div>`;

            return result;
          } else {
            // Total écart view
            const value = params[0].value;
            const sign = value > 0 ? "+" : "";
            const color = value > 0 ? "#67C23A" : "#F56C6C";

            let result = `<div style="font-weight:bold">Écart total</div>`;
            result += `<div style="color:${color};font-weight:bold">${sign}${props.formatAmount(value)}</div>`;
            // Add breakdown
            if (Object.keys(ecartsByMethod).length > 0) {
              result += `<div style="margin-top:5px;border-top:1px dashed #ddd;padding-top:5px;">`;
              result += `<div style="font-style:italic;font-size:11px;">Détail par méthode:</div>`;

              Object.entries(ecartsByMethod)
                .sort(([_, a], [__, b]) => Math.abs(b) - Math.abs(a))
                .forEach(([methodId, diff]) => {
                  const methodName = getPaymentMethodName(methodId);
                  const sign = diff > 0 ? "+" : "";
                  const color = diff > 0 ? "#67C23A" : "#F56C6C";

                  result += `<div style="margin-left:10px;font-size:11px;">`;
                  result += `${methodName}: `;
                  result += `<span style="color:${color};font-weight:bold">${sign}${props.formatAmount(diff)}</span>`;
                  result += `</div>`;
                });
            }

            return result;
          }
        }
      },
    },
    legend: {
      data: ["Dépôts", "Encaissements"],
      bottom: 0,
      selectedMode: false,
    },
    grid: [
      {
        left: "3%",
        right: "50%",
        bottom: "15%",
        top: "5%",
        containLabel: true,
      },
      {
        left: "55%",
        right: "3%",
        bottom: "15%",
        top: "5%",
        containLabel: true,
      },
    ],
    xAxis: [
      {
        type: "category",
        gridIndex: 0,
        data: methodNames,
        axisTick: { alignWithLabel: true },
        axisLabel: {
          fontSize: 11,
          interval: 0,
          rotate: methodNames.length > 2 ? 30 : 0,
          width: 100,
          overflow: "break",
        },
      },
      {
        type: "category",
        gridIndex: 1,
        data:
          showDetailedEcarts.value || ecartsMethodNames.length == 1
            ? ecartsMethodNames
            : ["Écart total"],
        axisTick: { alignWithLabel: true },
        axisLabel: {
          fontSize: 11,
          interval: 0,
          rotate: ecartsMethodNames.length > 2 ? 30 : 0,
          width: 100,
          overflow: "break",
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        gridIndex: 0,
        min: 0,
        max: maxValue,
        axisLabel: {
          formatter: (value) => props.formatAmount(value).replace(" €", ""),
          fontSize: 11,
        },
      },
      {
        type: "value",
        gridIndex: 1,
        min: -maxEcart,
        max: maxEcart,
        axisLabel: {
          formatter: (value) => props.formatAmount(value).replace(" €", ""),
          fontSize: 11,
        },
      },
    ],
    series: [depositsSeries, paymentsSeries, ...ecartSeries],
    animation: true,
    animationDuration: 800,
  };
});

// Check if we have multiple payment methods with écarts to determine if we should offer toggle
const hasMultiplePaymentMethods = computed(() => {
  const depositsByMethod = groupDepositsByMethod(
    props.selectedCashRegister.deposits,
  );
  const paymentsByMethod = groupPaymentsByMethod(
    props.selectedCashRegister.payments,
  );

  // Count methods with differences
  const methodsWithDiffs = Object.keys({
    ...depositsByMethod,
    ...paymentsByMethod,
  }).filter((methodId) => {
    const depositValue = depositsByMethod[methodId] || 0;
    const paymentValue = paymentsByMethod[methodId] || 0;
    return Math.abs(depositValue - paymentValue) >= 0.01;
  });

  return methodsWithDiffs.length > 1;
});
</script>
