<script lang="ts" setup>
import UnpaidInvoicesTable from "~/components/placer/dashboard/UnpaidInvoicesTable.vue";
import UnpaidInvoicesHeader from "~/components/placer/dashboard/UnpaidInvoicesHeader.vue";
import { DateTime } from "luxon";
const selectedMarket = ref();
const selectedDates = ref();
const loading = ref(false);
const unpaidInvoiceByMarketLoading = ref(false);
const filters = ref({});
const { formatDate, parseToDate } = useDateFormatter();
const { formatAmount } = useAmountFormatter();
const route = useRoute();
const { data: placer } = useNuxtData(`placer-dashboard-${route.params.id}`);
const marketIds = computed(() =>
  placer?.value?.data?.markets?.map((market) => market.id),
);
const apiFetch = useSanctumClient();
const onFilter = (filterEvent) => {
  filters.value = filterEvent;
};

const sortedMarkets = computed(() => {
  return placer?.value?.data?.markets.sort((a, b) =>
    a?.city.name?.localeCompare(b.city.name),
  );
});
const handleDateChange = async (newDates) => {
  selectedDates.value = newDates;
  await refreshMarketIncome();
};
const filteredTableData = computed(() => {
  if (!unpaidInvoiceByMarketAndMerchant.value) return [];

  const result = [];
  let filteredMarkets = [...unpaidInvoiceByMarketAndMerchant.value];

  if (filters.value.cities && filters.value.cities.length > 0) {
    filteredMarkets = filteredMarkets.filter((market) =>
      filters.value.cities.includes(market.city_name),
    );
  }
  if (filters.value.markets && filters.value.markets.length > 0) {
    filteredMarkets = filteredMarkets.filter((market) =>
      filters.value.markets.includes(market.market_name),
    );
  }

  filteredMarkets.forEach((market) => {
    // Start with all children
    let filteredMerchants = [...(market.children || [])];

    // Filter by merchant name if needed
    if (filters.value.merchants && filters.value.merchants.length > 0) {
      filteredMerchants = filteredMerchants.filter((merchant) =>
        filters.value.merchants.includes(merchant.merchant_name),
      );
    }
    if (filters.value.dates) {
      const [startDate, endDate] = filters.value.dates;
      if (startDate && endDate) {
        filteredMerchants = filteredMerchants.map((merchant) => {
          const merchantCopy = { ...merchant };

          merchantCopy.invoice_details = (
            merchant.invoice_details || []
          ).filter((invoice) => {
            if (invoice.period_start && invoice.period_end) {
              const periodStart = new Date(invoice.period_start);
              const periodEnd = new Date(invoice.period_end);

              // Only keep invoices where the entire period is within our selected date range
              return periodStart >= startDate && periodEnd <= endDate;
            }

            // If no period dates, fall back to created_at date
            const invoiceDate = new Date(invoice.created_at);
            return invoiceDate >= startDate && invoiceDate <= endDate;
          });

          // Recalculate the merchant's unpaid amount based on filtered invoices
          if (
            merchantCopy.invoice_details &&
            merchantCopy.invoice_details.length > 0
          ) {
            merchantCopy.total_unpaid_amount =
              merchantCopy.invoice_details.reduce(
                (sum, invoice) => sum + (invoice.due || 0),
                0,
              );
            merchantCopy.unpaid_invoice_count =
              merchantCopy.invoice_details.length;
          } else {
            // Reset values if no invoices match the filter
            merchantCopy.total_unpaid_amount = 0;
            merchantCopy.unpaid_invoice_count = 0;
          }

          return merchantCopy;
        });
        if (filters.value.amount) {
          const amountValue = filters.value.amount;
          const firstChar = amountValue.charAt(0);

          // Apply the appropriate comparison based on the prefix
          if (firstChar === ">") {
            // Greater than

            const numericValue = parseFloat(amountValue.substring(1));
            filteredMerchants = filteredMerchants.filter((merchant) => {
              return merchant.total_unpaid_amount > numericValue;
            });
          } else if (firstChar === "<") {
            // Less than
            const numericValue = parseFloat(amountValue.substring(1));
            filteredMerchants = filteredMerchants.filter(
              (merchant) => merchant.total_unpaid_amount < numericValue,
            );
          } else if (firstChar === "=") {
            // Equal to (with a small tolerance for floating-point comparison)
            const numericValue = parseFloat(amountValue.substring(1));
            const tolerance = 0.01; // 1 cent tolerance
            filteredMerchants = filteredMerchants.filter(
              (merchant) =>
                Math.abs(merchant.total_unpaid_amount - numericValue) <
                tolerance,
            );
          } else if (amountValue.includes("-")) {
            // For backward compatibility: range format (min-max)
            const [min, max] = amountValue.split("-").map(Number);
            filteredMerchants = filteredMerchants.filter((merchant) => {
              const amount = merchant.total_unpaid_amount;
              return amount >= min && amount <= max;
            });
          } else {
            // For backward compatibility: single value without operator means greater than
            const numericValue = parseFloat(amountValue);
            if (!isNaN(numericValue)) {
              filteredMerchants = filteredMerchants.filter(
                (merchant) => merchant.total_unpaid_amount > numericValue,
              );
            }
          }
        }

        // Then, filter merchants to only include those with at least one invoice detail
        filteredMerchants = filteredMerchants.filter(
          (merchant) =>
            merchant.invoice_details && merchant.invoice_details.length > 0,
        );
      }
    }

    // If there are merchants after filtering, add them to the result
    if (filteredMerchants.length > 0) {
      // Calculate market total from filtered merchants
      const marketTotal = filteredMerchants.reduce(
        (sum, merchant) => sum + merchant.total_unpaid_amount,
        0,
      );

      // Calculate total invoice count
      const marketInvoiceCount = filteredMerchants.reduce(
        (sum, merchant) => sum + merchant.unpaid_invoice_count,
        0,
      );

      // Add each merchant with market info to the flattened result
      filteredMerchants.forEach((merchant) => {
        result.push({
          market_name: market.market_name,
          market_total_amount: marketTotal,
          market_invoice_count: marketInvoiceCount,
          city_name: market.city_name,

          merchant_name: merchant.merchant_name,
          total_unpaid_amount: merchant.total_unpaid_amount,
          unpaid_invoice_count: merchant.unpaid_invoice_count,
          invoice_details: merchant.invoice_details,
          market_id: merchant.market_id,
          merchant_id: merchant.merchant_id,
        });
      });
    }
  });

  return result;
});
const sectorTotalUnpaidAmount = computed(() => {
  let totalAmount = 0;
  const marketsAlreadyDone = {};
  filteredTableData.value?.forEach((data) => {
    if (data?.market_id && !marketsAlreadyDone[data.market_id]) {
      marketsAlreadyDone[data.market_id] = true;
      totalAmount += data?.market_total_amount;
    }
  });
  return formatAmount(totalAmount);
});

const {
  data: marketRevenue,
  refresh: refreshMarketIncome,
  status: marketRevenueStatus,
} = await useAsyncData(
  () => {
    if (selectedMarket.value == null) {
      return;
    }
    const query = {};
    if (selectedDates?.value) {
      const [startDate, endDate] = selectedDates.value;
      if (startDate) {
        query.start_date = formatDate(startDate, false, DateTime.DATE_SHORT);
      }
      if (endDate && startDate) {
        query.end_date = formatDate(endDate, false, DateTime.DATE_SHORT);
      }
    }
    return apiFetch(`/api/markets/${selectedMarket.value}/income`, {
      query,
    });
  },
  {
    watch: selectedMarket,
  },
);
const {
  data: unpaidInvoiceByMarketAndMerchant,
  refresh: refreshUnpaidInvoiceByMarketAndMerchant,
  status: unpaidInvoiceByMarketAndMerchantStatus,
} = await useAsyncData(
  () =>
    apiFetch("/api/markets/unpaid-invoice-by-market-and-merchant", {
      method: "post",
      body: { market_ids: marketIds.value ?? [] },
    }),
  {
    watch: marketIds,
  },
);

watch(unpaidInvoiceByMarketAndMerchantStatus, (newStatus) => {
  if (newStatus == "success" || newStatus == "error") {
    unpaidInvoiceByMarketLoading.value = false;
  } else {
    unpaidInvoiceByMarketLoading.value = true;
  }
});
</script>
<template>
  <div class="grid gap-4 grid-cols-[minmax(150px,_1fr)_1fr_1fr]">
    <div class="flex flex-col">
      <h2 class="p-4 font-bold bg-white rounded-t-lg">
        Marchés de {{ placer?.data?.name }}
      </h2>
      <Listbox v-model="selectedMarket" :options="sortedMarkets" option-value="id" :pt="{
        root: {
          class: 'border-none rounded-b-lg shadow-none',
        },
        listContainer: {
          class: 'max-h-[350px] overflow-y-auto ',
        },
        list: {
          class: 'p-[0.5rem 1rem 0.25rem 1rem] ml-4 space-y-2',
        },
        option: ({ context }) => ({
          class: [
            'w-full transition-colors p-0 m-0',
            'border-b border-gray-200 last:border-b-0',
            context.selected
              ? '!bg-blue-50 !border-l-3 !border-l-primary'
              : '',
            context.index % 2 === 0 ? 'bg-gray-50' : 'bg-white',
            'hover:bg-gray-100',
          ],
        }),
        emptyMessage: {
          class: 'p-4 text-gray-500 bg-white',
        },
      }" @change="refreshMarketIncome" filter>
        <template #option="slotProps">
          <div class="flex justify-between items-center w-full">
            <div>
              {{ slotProps.option.name }}
            </div>
            <Tag :value="slotProps.option.billing_period.name" />
          </div>
        </template>
      </Listbox>
    </div>

    <div v-if="selectedMarket">
      <div>
        <ProgressSpinner v-if="loading" style="width: 50px; height: 50px" strokeWidth="8" fill="transparent"
          animationDuration=".5s" aria-label="Custom ProgressSpinner" />
        <LazyMarketRevenue v-else-if="marketRevenue" :market-revenue="marketRevenue"
          :loading="marketRevenueStatus != 'success'" @dateChange="handleDateChange" />
      </div>
    </div>

    <div class="col-start-3">
      <Card class="overflow-hidden mb-8 rounded-lg border-0 shadow-md">
        <template #header>
          <div class="p-4 bg-red-600">
            <h1 class="text-xl font-semibold text-white">
              {{ "Total Impayée du secteur" }}
            </h1>
          </div>
        </template>
        <template #content>
          <div class="flex gap-4 items-center p-4 bg-white border-l-4 border-red-600 dark:bg-gray-800">
            <span class="text-2xl font-bold text-gray-900 dark:text-white">
              {{ sectorTotalUnpaidAmount }}
            </span>
          </div>
        </template>
      </Card>
    </div>
    <div class="col-span-full row-start-2">
      <ProgressSpinner v-if="loading" :pt="{
        root: { class: 'w-6 h-6' },
        spinner: { class: 'stroke-primary' },
      }" />
      <UnpaidInvoicesHeader :loading="unpaidInvoiceByMarketLoading" @refresh-placer-dashboard-unpaid-invoice-table="refreshUnpaidInvoiceByMarketAndMerchant
        " :markets="placer?.data?.markets || []" :value="unpaidInvoiceByMarketAndMerchant" @filter-change="onFilter" />
      <UnpaidInvoicesTable :loading="unpaidInvoiceByMarketLoading" :value="filteredTableData" :filters />
    </div>
  </div>
</template>
