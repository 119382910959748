export type MethodPayment = {
  id: number;
  name: string;
  code: string;
};
export enum PaymentMethod {
  Cash = 1,
  Check = 2,
  BankDraft = 3,
  BankTransfer = 4,
  CreditCard = 5
}
