<script setup lang="ts">
import MerchantAbsencesChart from "./MerchantAbsencesChart.vue";

const { formatAmount } = useAmountFormatter();
const { formatDate } = useDateFormatter();

const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  value: {
    type: Array,
    required: true,
  },
  filters: {
    required: false,
  },
});
const tableData = computed(() => props.value || []);
const dialog = useDialog();
const expandedRowGroups = ref();
const showAbsencesChart = (merchantId, marketId = null) => {
  dialog.open(MerchantAbsencesChart, {
    props: {
      style: {
        width: "90vw",
        maxWidth: "1200px",
      },
      breakpoints: {
        "960px": "90vw",
        "640px": "100vw",
      },
      modal: true,
      closeOnEscape: true,
      dismissableMask: false,
      header: "Présences et absences",
    },
    data: {
      merchantId: merchantId,
      marketId: marketId,
      initialStartDate: props.filters?.dates?.[0],
      initialEndDate: props.filters?.dates?.[1],
    },
  });
};
</script>

<template>
  <DataTable :value="tableData" :loading="loading" v-model:expandedRowGroups="expandedRowGroups" expandableRowGroups
    rowGroupMode="subheader" groupRowsBy="market_name" sortMode="single" sortField="city_id" :pt="{
      table: { class: 'min-w-[50rem]' },
      header: { class: 'bg-gray-50 p-4' },
      headerRow: { class: 'border-b border-gray-200' },
      headerCell: { class: 'text-gray-700 font-semibold' },
      bodyRow: {
        class: 'border-b border-gray-100 hover:bg-gray-50 transition-colors',
      },
      bodyCell: { class: 'p-4' },
      rowGroupHeader: {
        class:
          'bg-surface-100 cursor-pointer hover:bg-surface-200 transition-colors',
      },
    }" currentPageReportTemplate="Résultats {first}-{last} sur {totalRecords}"
    paginatorTemplate="RowsPerPageDropdown CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink">
    <!-- Group header template -->
    <template #groupheader="slotProps">
      <div class="inline-flex gap-4 justify-between items-center p-4">
        <div class="flex flex-col gap-1">
          <div class="inline-flex gap-3 items-center">
            <i class="text-xl pi pi-shopping-cart text-surface-600"></i>
            <span class="text-xl font-medium text-surface-900">{{
              slotProps.data.market_name
              }}</span>
            <span class="ml-2 text-sm text-gray-500">({{ slotProps.data.market_invoice_count }} factures)</span>
          </div>
          <div class="flex gap-2 items-center ml-7">
            <i class="text-gray-400 pi pi-map-marker"></i>
            <span class="text-sm text-gray-500">{{
              slotProps.data.city_name
              }}</span>
          </div>
        </div>
        <div class="inline-flex gap-4 items-center">
          <Tag severity="danger" :value="formatAmount(slotProps.data.market_total_amount)" :pt="{
            root: { class: 'px-3 py-2' },
            value: { class: 'text-lg font-semibold' },
          }" />
        </div>
      </div>
    </template>

    <!-- Table columns -->
    <Column field="market_name" header="Marché" />
    <!-- Hidden but needed for grouping -->
    <Column field="merchant_name" header="Commerçant" style="width: 15%" />
    <Column field="unpaid_invoice_count" header="Nombre de factures" style="width: 10%" />
    <Column field="total_unpaid_amount" header="Montant impayé" style="width: 35%">
      <template #body="slotProps">
        <Panel class="border-none" :header="formatAmount(slotProps.data.total_unpaid_amount)" toggleable collapsed>
          <template #toggleicon="{ collapsed }">
            <i class="pi" :class="collapsed ? 'pi-chevron-down' : 'pi-chevron-up'" />
          </template>
          <div class="space-y-2">
            <div class="grid overflow-y-auto grid-cols-2 gap-3 p-1 sm:grid-cols-3 lg:grid-cols-4 max-h-[400px]">
              <div v-for="detail in slotProps.data.invoice_details" :key="detail.uuid"
                class="p-3 bg-gray-50 rounded-lg transition-colors hover:bg-gray-100">
                <!-- Invoice Header -->
                <div class="flex gap-1.5 items-center mb-2">
                  <i class="text-sm text-gray-400 pi pi-file"></i>
                  <span class="text-xs font-medium text-gray-600">{{
                    detail.uuid
                    }}</span>
                </div>
                <!-- Invoice Details -->
                <div class="space-y-2">
                  <div class="flex justify-between items-center">
                    <span class="text-xs text-gray-500">Date:</span>
                    <Tag class="text-center" :value="'du ' +
                      formatDate(detail.period_start) +
                      ' au ' +
                      formatDate(detail.period_end)
                      " severity="info" :pt="{
                        root: { class: 'px-1.5 py-0.5' },
                        value: { class: 'text-xs' },
                      }" />
                  </div>
                  <div class="flex justify-between items-center">
                    <span class="text-xs text-gray-500">Total:</span>
                    <span class="text-xs font-medium">{{
                      formatAmount(detail.total_incl_tax)
                      }}</span>
                  </div>
                  <div class="flex justify-between items-center">
                    <span class="text-xs text-gray-500">À payer:</span>
                    <span class="text-xs font-medium text-red-600">{{
                      formatAmount(detail.due)
                      }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Panel>
      </template>
    </Column>
    <Column header="Absences/Presence" style="width: 5%">
      <template #body="slotProps">
        <div class="flex justify-center p-2">
          <Button icon="pi pi-chart-bar" severity="secondary" raised :pt="{
            root: {
              class:
                'hover:bg-gray-100 rounded rounded-lg p-2 bg-surface-100 border-slate-100',
            },
            icon: { class: 'text-slate-900' },
          }" @click="
              showAbsencesChart(
                slotProps.data.merchant_id,
                slotProps.data.market_id,
              )
              " v-tooltip.top="'Voir les présences et absences'" />
        </div>
      </template>
    </Column>
  </DataTable>
</template>
