<script setup lang="ts">
const emit = defineEmits([
  "refresh-placer-dashboard-unpaid-invoice-table",
  "filter-change",
]);

const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  markets: {
    type: Array,
    default: () => [],
  },
  value: {
    type: Array,
    default: () => [],
  },
});

const filters = ref({
  dates: null,
  amount: null,
  markets: [],
  merchants: [],
  cities: [],
});

// Simple computed for all cities
const citiesOptions = computed(() => {
  if (!props.value) return [];
  const cities = new Set(props.value.map((market) => market.city_name));
  return Array.from(cities)
    .sort()
    .map((name) => ({ label: name, value: name }));
});

// Markets filtered by selected cities
const marketOptions = computed(() => {
  if (!props.markets) return [];
  if (!filters.value.cities.length) {
    return props.markets.map((market) => ({
      label: market.name,
      value: market.name,
    }));
  }

  const selectedCities = new Set(filters.value.cities);
  const cityByMarket = new Map(
    props.value.map((market) => [market.market_name, market.city_name]),
  );

  return props.markets
    .filter((market) => selectedCities.has(cityByMarket.get(market.name)))
    .map((market) => ({
      label: market.name,
      value: market.name,
    }));
});
const merchantOptions = computed(() => {
  if (!props.value) {
    return [];
  }
  const merchantNames = new Set();
  props.value.forEach((market) => {
    if (market.children && Array.isArray(market.children)) {
      market.children.forEach((merchant) => {
        if (merchant.merchant_name && merchant.merchant_name.trim() !== "") {
          merchantNames.add(merchant.merchant_name);
        }
      });
    }
  });

  return Array.from(merchantNames)
    .sort()
    .map((name) => ({ label: name, value: name }));
});

watch(
  filters,
  (newFilters) => {
    emit("filter-change", newFilters);
  },
  { deep: true },
);

const handleRefresh = () => {
  emit("refresh-placer-dashboard-unpaid-invoice-table");
};
</script>

<template>
  <div class="p-4 mb-4 bg-white rounded-lg shadow-sm">
    <div class="flex justify-between items-center mb-4">
      <h2 class="text-xl font-semibold text-gray-800">Suivi des Impayés</h2>
      <Button icon="pi pi-refresh" :loading="loading" @click="handleRefresh" severity="secondary" text :pt="{
        root: { class: 'p-2' },
        icon: { class: 'text-gray-600' },
      }" />
    </div>

    <div class="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
      <!-- Filtre par date -->
      <div class="flex flex-col">
        <label class="mb-2 text-sm text-gray-600">Période</label>
        <DatePicker v-model="filters.dates" selectionMode="range" :showButtonBar="true" :manualInput="false"
          placeholder="Sélectionner une période" class="w-full" :pt="{
            input: { class: 'w-full' },
          }" />
      </div>

      <!-- Filtre par montant -->
      <div class="flex flex-col">
        <label class="mb-2 text-sm text-gray-600">Montant</label>
        <TableFiltersAmount v-model="filters.amount" :standalone="true" :maxFractionDigits="10" />
      </div>
      <div class="flex flex-col">
        <label class="mb-2 text-sm text-gray-600">Ville</label>
        <MultiSelect v-model="filters.cities" :options="citiesOptions" optionLabel="label" optionValue="value"
          :virtualScrollerOptions="{ itemSize: 38 }" placeholder="Sélectionner une ou plusieurs ville" class="w-full"
          filter :showToggleAll="true" display="chip" :pt="{
            panel: { class: 'max-h-60 overflow-auto' },
            header: { class: 'p-2 bg-gray-50 sticky top-0 z-10' },
            wrapper: { class: 'flex flex-wrap overflow-y-auto max-h-32' },
          }" />
      </div>
      <!-- Filtre par marché -->
      <div class="flex flex-col">
        <label class="mb-2 text-sm text-gray-600">Marché</label>
        <MultiSelect v-model="filters.markets" :options="marketOptions" optionLabel="label" optionValue="value"
          :virtualScrollerOptions="{ itemSize: 38 }" placeholder="Sélectionner un ou plusieurs marchés" class="w-full"
          filter :showToggleAll="true" display="chip" :pt="{
            panel: { class: 'max-h-60 overflow-auto' },
            header: { class: 'p-2 bg-gray-50 sticky top-0 z-10' },
            wrapper: { class: 'flex flex-wrap overflow-y-auto max-h-32' },
          }" />
      </div>

      <!-- Filtre par commerçant -->
      <div class="flex flex-col">
        <label class="mb-2 text-sm text-gray-600">Commerçant</label>
        <MultiSelect v-model="filters.merchants" :options="merchantOptions" optionLabel="label" optionValue="value"
          :virtualScrollerOptions="{ itemSize: 38 }" placeholder="Sélectionner un ou plusieurs commerçants"
          class="w-full" filter :showToggleAll="true" display="chip" :pt="{
            panel: { class: 'max-h-60 overflow-auto' },
            header: { class: 'p-2 bg-gray-50 sticky top-0 z-10' },
            wrapper: { class: 'flex flex-wrap overflow-y-auto max-h-32' },
          }" />
      </div>
    </div>
  </div>
</template>
