<template>
  <BaseChart title="Répartition des encaissements" :chart-options="paymentsPieChartOptions" />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { PieChart } from "echarts/charts";
import { use } from "echarts/core";
import BaseChart from "./BaseChart.vue";
import {
  getPaymentMethodName,
  getPaymentMethodColor,
  groupPaymentsByMethod,
} from "~/utils/paymentMethod";

use([PieChart]);

const props = defineProps({
  selectedCashRegister: {
    type: Object,
    required: true,
  },
  formatAmount: {
    type: Function,
    required: true,
  },
});

const paymentsTotal = computed(() => {
  if (!props.selectedCashRegister?.payments) return 0;
  return props.selectedCashRegister.payments.reduce(
    (sum, payment) => sum + parseFloat(payment.amount || 0),
    0,
  );
});

const paymentsPieChartOptions = computed(() => {
  const paymentsByMethod = groupPaymentsByMethod(
    props.selectedCashRegister?.payments || [],
  );

  const pieData = Object.entries(paymentsByMethod).map(
    ([methodId, amount]) => ({
      name: getPaymentMethodName(methodId),
      value: amount,
    }),
  );

  return {
    title: {
      text: `Total: ${props.formatAmount(paymentsTotal.value)}`,
      left: "center",
      top: "bottom",
      textStyle: {
        fontSize: 14,
        fontWeight: "normal",
      },
    },
    tooltip: {
      trigger: "item",
      formatter: (params) => {
        return `${params.name}: ${props.formatAmount(params.value)} (${params.percent}%)`;
      },
    },
    legend: {
      orient: "vertical",
      left: "left",
      type: "scroll",
    },
    series: [
      {
        name: "Encaissements",
        type: "pie",
        radius: "65%",
        center: ["50%", "50%"],
        data: pieData,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        label: {
          formatter: "{b}: {d}%",
        },
        itemStyle: {
          color: (params) =>
            getPaymentMethodColor(
              Object.keys(paymentsByMethod)[params.dataIndex],
            ),
        },
      },
    ],
    graphic:
      pieData.length === 0
        ? [
          {
            type: "text",
            left: "center",
            top: "middle",
            style: {
              text: "Aucun paiement",
              fill: "#999",
              fontSize: 14,
            },
          },
        ]
        : undefined,
  };
});
</script>
