<template>
  <div class="attendance-chart-container">
    <!-- Loading state -->
    <div v-if="loading" class="loading-container">
      <ProgressSpinner />
      <div class="loading-text">Chargement des données en cours...</div>
    </div>

    <template v-else>
      <div class="flex mb-2 date-filters justify-content-between">
        <div class="flex align-items-center">
          <span class="mr-2">Période:</span>
          <DatePicker v-model="dateRange" selectionMode="range" dateFormat="dd/mm/yy" inputClass="p-inputtext-sm"
            :showIcon="true" showButtonBar class="mr-2">
            <template v-slot:header>
              <InlineMessage severity="info">Sélectionner une plage de date</InlineMessage>
            </template>
          </DatePicker>
          <Button icon="pi pi-filter" label="Appliquer" @click="refreshData" size="small" />
        </div>
      </div>

      <div class="mb-3 stats-section">
        <div class="stats-grid">
          <div class="stat-card primary-stat">
            <div class="stat-value">{{ summaryData.attendance_rate }}%</div>
            <div class="stat-label">Taux de présence</div>
          </div>
          <div class="stat-card success-stat">
            <div class="stat-value">{{ summaryData.present_count }}</div>
            <div class="stat-label">
              Présences
              <span v-if="summaryData.total_days">({{
                formatPercentage(
                  summaryData.present_count / summaryData.total_days,
                )
              }})</span>
            </div>
          </div>
          <div class="stat-card warning-stat">
            <div class="stat-value">{{ summaryData.justified_count }}</div>
            <div class="stat-label">
              Abs. justifiées
              <span v-if="summaryData.total_days">({{
                formatPercentage(
                  summaryData.justified_count / summaryData.total_days,
                )
              }})</span>
            </div>
          </div>
          <div class="stat-card danger-stat">
            <div class="stat-value">{{ summaryData.absent_count }}</div>
            <div class="stat-label">
              Abs. non justifiées
              <span v-if="summaryData.total_days">({{
                formatPercentage(
                  summaryData.absent_count / summaryData.total_days,
                )
              }})</span>
            </div>
          </div>
        </div>
      </div>

      <div class="chart-section">
        <div class="chart-container">
          <VChart :option="chartOptions" />
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { use } from "echarts/core";
import { BarChart } from "echarts/charts";
import {
  TooltipComponent,
  LegendComponent,
  DataZoomComponent,
  GridComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";

use([
  TooltipComponent,
  LegendComponent,
  DataZoomComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
]);

const { $eventBus } = useNuxtApp();

interface Props {
  merchantId: number;
  marketId?: number | null;
  initialStartDate?: string;
  initialEndDate?: string;
}

const props = withDefaults(defineProps<Props>(), {
  marketId: null,
  initialStartDate: () => {
    const date = new Date();
    date.setMonth(date.getMonth() - 3);
    return date.toISOString().split("T")[0];
  },
  initialEndDate: () => {
    return new Date().toISOString().split("T")[0];
  },
});

const dialogRef = inject("dialogRef");
const merchantId = computed(
  () => props?.merchantId ?? dialogRef?.value?.data?.merchantId,
);
const marketId = computed(
  () => props?.marketId ?? dialogRef?.value?.data?.marketId,
);
const loading = ref(true);
const dateRange = ref([
  new Date(dialogRef?.value?.data?.initialStartDate ?? props.initialStartDate),
  new Date(dialogRef?.value?.data?.initialEndDate ?? props.initialEndDate),
]);
const merchantData = ref({ id: null, name: "" });
const marketData = ref(null);
const summaryData = ref({
  total_days: 0,
  present_count: 0,
  justified_count: 0,
  absent_count: 0,
  attendance_rate: 0,
});
const attendanceData = ref({
  dates: [],
  statuses: [],
  details: [],
});

const updateDialogTitle = () => {
  if (dialogRef.value) {
    let title = "Présences et absences";
    if (merchantData.value?.name) {
      title += ` - ${merchantData.value.name}`;
      if (marketData.value && marketData.value.name) {
        title += ` - ${marketData.value.name}`;
      } else if (marketData.value === null) {
        title += " - Tous les marchés";
      }
    }
    dialogRef.value.options.header = title;
  }
};

const chartOptions = ref({
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "shadow",
    },
    formatter: function (params) {
      const date = params[0].axisValue;
      let result = `${date}<br/>`;

      // Find the active data point that has a value of 1
      const activeParams = params.filter((p) => p.value === 1);
      if (activeParams.length > 0) {
        const activeParam = activeParams[0];
        result += `${activeParam.marker} ${activeParam.seriesName}`;
      }

      return result;
    },
  },
  legend: {
    data: ["Présent", "Absence justifiée", "Absence non justifiée"],
    top: 0,
  },
  grid: {
    left: "3%",
    right: "4%",
    bottom: "15%",
    containLabel: true,
  },
  dataZoom: [
    {
      type: "slider",
      show: true,
      start: 0,
      end: 100,
      height: 20,
      bottom: 0,
    },
  ],
  xAxis: {
    type: "category",
    data: [],
    axisLabel: {
      interval: 0,
      rotate: 45,
      formatter: function (value) {
        // Format date to DD/MM
        const date = new Date(value);
        return `${date.getDate().toString().padStart(2, "0")}/${(date.getMonth() + 1).toString().padStart(2, "0")}`;
      },
    },
  },
  yAxis: {
    type: "value",
    show: false,
    min: 0,
    max: 1,
    splitLine: {
      show: false,
    },
  },
  series: [
    {
      name: "Présent",
      type: "bar",
      stack: "attendance",
      data: [],
      itemStyle: {
        color: "#67C23A",
      },
      barGap: 0,
      barCategoryGap: "10%",
      emphasis: {
        itemStyle: {
          color: "#4CAF50",
        },
      },
    },
    {
      name: "Absence justifiée",
      type: "bar",
      stack: "attendance",
      data: [],
      itemStyle: {
        color: "#E6A23C",
      },
      emphasis: {
        itemStyle: {
          color: "#FF9800",
        },
      },
    },
    {
      name: "Absence non justifiée",
      type: "bar",
      stack: "attendance",
      data: [],
      itemStyle: {
        color: "#F56C6C",
      },
      emphasis: {
        itemStyle: {
          color: "#F44336",
        },
      },
    },
  ],
});

const refreshData = async () => {
  loading.value = true;

  const startDate = formatDateForAPI(dateRange.value[0]);
  const endDate = formatDateForAPI(dateRange.value[1]);

  const params = {
    merchant_id: merchantId.value,
    start_date: startDate,
    end_date: endDate,
  };

  if (marketId.value) {
    params.market_id = marketId.value;
  }

  try {
    const { data } = await useSanctumFetch("/api/merchants/attendance", {
      method: "GET",
      params,
    });

    if (data.value) {
      merchantData.value = data.value.merchant;
      marketData.value = data.value.market;
      summaryData.value = data.value.summary;
      attendanceData.value = data.value.attendance_data;

      updateDialogTitle();
      updateChartData();
    }
  } catch (error) {
    console.error("Error loading attendance data:", error);
    $eventBus.emit("toast", {
      severity: "error",
      summary: "Erreur",
      detail: "Erreur lors du chargement des données de présence",
      life: 3000,
    });
  } finally {
    loading.value = false;
  }
};

const updateChartData = () => {
  chartOptions.value.xAxis.data = attendanceData.value.dates;

  // Prepare data for each series
  const presentData = [];
  const justifiedData = [];
  const absentData = [];

  // Process statuses to create data points
  attendanceData.value.statuses.forEach((status) => {
    if (status === "present") {
      presentData.push(1);
      justifiedData.push(0);
      absentData.push(0);
    } else if (status === "justified") {
      presentData.push(0);
      justifiedData.push(1);
      absentData.push(0);
    } else if (status === "absent") {
      presentData.push(0);
      justifiedData.push(0);
      absentData.push(1);
    } else {
      // No data
      presentData.push(0);
      justifiedData.push(0);
      absentData.push(0);
    }
  });

  // Update series data
  chartOptions.value.series[0].data = presentData;
  chartOptions.value.series[1].data = justifiedData;
  chartOptions.value.series[2].data = absentData;
};

const formatDateForAPI = (date) => {
  if (!date) return "";
  return date.toISOString().split("T")[0];
};

const formatPercentage = (value) => {
  return `${Math.round(value * 100)}%`;
};

// Initialize when component mounts
onMounted(() => {
  refreshData();
});
</script>

<style scoped>
.attendance-chart-container {
  padding: 0.5rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-3 {
  margin-bottom: 0.75rem;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  background: var(--surface-ground);
  border-radius: 4px;
  height: 200px;
}

.loading-text {
  margin-top: 0.75rem;
  color: var(--text-color-secondary);
}

/* Compact stats grid */
.stats-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.stat-card {
  flex: 1;
  min-width: 120px;
  padding: 0.75rem;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.stat-value {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 0.25rem;
}

.stat-label {
  font-size: 0.8rem;
  color: var(--text-color-secondary);
  line-height: 1.2;
}

.primary-stat {
  background: rgba(var(--primary-rgb, 59, 130, 246), 0.1);
  border: 1px solid var(--primary-300);
}

.primary-stat .stat-value {
  color: var(--primary-700);
}

.success-stat {
  background: rgba(34, 197, 94, 0.1);
  border: 1px solid rgba(34, 197, 94, 0.3);
}

.success-stat .stat-value {
  color: #1e4620;
}

.warning-stat {
  background: rgba(245, 158, 11, 0.1);
  border: 1px solid rgba(245, 158, 11, 0.3);
}

.warning-stat .stat-value {
  color: #9a3412;
}

.danger-stat {
  background: rgba(239, 68, 68, 0.1);
  border: 1px solid rgba(239, 68, 68, 0.3);
}

.danger-stat .stat-value {
  color: #991b1b;
}

.chart-container {
  height: 300px;
  width: 100%;
  border: 1px solid var(--surface-border);
  border-radius: 4px;
  background: var(--surface-card);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .stats-grid {
    flex-direction: column;
  }

  .stat-card {
    width: 100%;
  }

  .stat-value {
    font-size: 1.25rem;
  }

  .chart-container {
    height: 250px;
  }
}
</style>
